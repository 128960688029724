import React from 'react'
import { Link } from 'react-scroll'
import { FaLinkedinIn, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';
const ContactUs = () => {
  return (

      <section id="Contact" 
    className="relative w-full h-auto bg-gradient-to-b from-[rgb(48,0,0)] to-black py-12 px-4">
      <h1 className="relative text-center font-bold text-white text-3xl sm:text-4xl">
        Contact Us
      </h1>

      <p className="text-left text-white mb-12 max-w-3xl mx-auto mt-10 px-4 sm:px-0">
        Got a question, an idea, or a project you'd like to bring to life? We'd
        love to hear from you! Whether you're looking to collaborate,
        discuss our games, or simply say hello, feel free to reach out. Let's
        create something amazing together!
      </p>

      <div className="flex flex-col md:flex-row justify-center items-center md:items-start space-y-8 md:space-y-0 md:space-x-8">
        <div className="rounded-lg bg-white p-8 w-full max-w-[350px] flex flex-col justify-between h-[450px]">
          <div>
            <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
            <p className="mb-8">Want to get in touch?</p>
          </div>

          <div className="space-y-4 mb-8">
            <div className="flex items-center">
              <svg className="w-6 h-6 mr-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
              </svg>
              <a href="tel:+21652993317" className="hover:text-gray-800">+216 52 993 317</a>
            </div>
            <div className="flex items-center">
              <svg className="w-6 h-6 mr-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
              <a href="mailto:contact@SpicyHarissa.com" className="hover:text-gray-800">contact@SpicyHarissa.com</a>
            </div>
            <div className="flex items-center">
              <svg className="w-6 h-6 mr-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
              </svg>
              <span className="hover:text-gray-800">Tunisia, Sousse</span>
            </div>
          </div>

          <div className="flex space-x-4">
            <a href="#">
            <img
            src="/Svgs/Twitter.svg"
            alt="Twitter Icon"
            />
            </a>
            <a href="#">
            <img
            src="/Svgs/Instagram.svg"
            alt="Instagram Icon"
            />
            </a>
            <a href="#">
            <img
            src="/Svgs/Discord.svg"
            alt="Discord Icon"
            />
            </a>
            <a href="#">
            <img
            src="/Svgs/Linkedin.svg"
            alt="Linkedin Icon"
            />
            </a>
          </div>
        </div>

        <form action="https://formspree.io/f/xnnarbqg" method="POST" className="p-4 sm:p-8 w-full max-w-[700px] bg-transparent h-full mx-auto">
          <div className="grid grid-cols-1 gap-4 sm:gap-6 ">
            {/* <div className="w-full">
              <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-white">First Name</label>
              <input type="text" id="first_name" className="w-full bg-transparent border-b border-gray-300 text-white text-sm focus:outline-none block p-2.5 mb-2" placeholder="John" required />
            </div>
            <div className="w-full">
              <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-white">Last Name</label>
              <input type="text" id="last_name" className="w-full bg-transparent border-b border-gray-300 text-white text-sm focus:outline-none block p-2.5 mb-2" placeholder="Doe" required />
            </div> */}
            <div className="w-full">
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-white">Email</label>
              <input type="email" id="email" className="w-full bg-transparent border-b border-gray-300 text-white text-sm focus:outline-none block p-2.5 mb-2" placeholder="john.doe@example.com" required />
            </div>
{/*             <div className="w-full">
              <label htmlFor="phone" className="block mb-2 text-sm font-medium text-white">Phone Number</label>
              <input type="tel" id="phone" className="w-full bg-transparent border-b border-gray-300 text-white text-sm focus:outline-none block p-2.5 mb-2" placeholder="+1 (555) 123-4567" required />
            </div> */}
          </div>
          <div className="mt-4 sm:mt-6">
            <label htmlFor="message" className="block mb-2 text-sm font-medium text-white">Message</label>
            <textarea id="message" rows={4} className="w-full bg-transparent border-b border-gray-300 text-white text-sm focus:outline-none block p-2.5" placeholder="Write your message here..."></textarea>
          </div>
          <div className="mt-4 sm:mt-6 text-right">
            <button type="submit" className="text-black bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Send Message</button>
          </div>
        </form>
      </div>
    </section>
  )
}
function FacebookIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
    </svg>
  )
}


function InstagramIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
      <line x1="17.5" x2="17.51" y1="6.5" y2="6.5" />
    </svg>
  )
}


function LinkedinIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
      <rect width="4" height="12" x="2" y="9" />
      <circle cx="4" cy="4" r="2" />
    </svg>
  )
}


function MailIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="16" x="2" y="4" rx="2" />
      <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
    </svg>
  )
}


function MapPinIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
      <circle cx="12" cy="10" r="3" />
    </svg>
  )
}


function PhoneIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
    </svg>
  )
}


function TwitterIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
    </svg>
  )
}

export default ContactUs