import './App.css';
import NavBar from './Components/Navbar/NavBar';
import Home from './Components/Home/Home';
import AboutUs from './Components/Aboutus/AboutUs';
import Projects from './Components/Projects/Projects';
import Footer from './Components/Footer/Footer';
import ContactUs from './Components/Contactus/ContactUs';
import Services from './Components/Services/Services';
import WhyUs from './Components/Whyus/Whyus'
function App() {
  return (
    <>
      <div className=" bg-gray-950 ">
        <NavBar />
        <Home />
        <Services/>
        <Projects />
        <AboutUs />
        
        <WhyUs/>
        <ContactUs />
        <Footer />
      </div>
    </>
  );
}

export default App;
